import React, { useState } from 'react'
import { Link, NavLink, withRouter } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import 'src/assets/scss/style.scss'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { pathuser } from 'src/functions/Main'
const Login = withRouter(({ history }) => {
  toast.configure()
  const dispatch = useDispatch()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const url = `${process.env.REACT_APP_MAIN_URL}/api/users/login`

  const loginHandler = () => {
    const dataToSend = {
      email,
      password,
    }
    fetch(url, {
      method: 'POST',
      headers: new Headers({
        Accept: 'application/json',
        // Authorization: 'Bearer ' + user.token,
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(dataToSend),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.errorCode) {
          return toast.error(res.errorMessage, {
            position: toast.POSITION.TOP_CENTER,
          })
        }

        localStorage.setItem('sff_token', res.token)
        // const path = pathuser(res.role)
        // history.push(`/dashboard/${path}`)
        window.location.reload(false)
        // dispatch({ type: 'store_user', payload: toSend })

        return toast.success(res.message, {
          position: toast.POSITION.TOP_CENTER,
        })
      })
      .catch((err) => {
        console.log(err)
        return toast.warning('An error occured while reaching the server', {
          position: toast.POSITION.TOP_CENTER,
        })
      })
    // return console.log(toSend)
  }
  return (
    <div className="auth-wrapper">
      <div className="auth-content">
        <div className="auth-bg">
          <span className="r" />
          <span className="r s" />
          <span className="r s" />
          <span className="r" />
        </div>
        <div className="card">
          <div className="card-body text-center">
            <div className="mb-4">
              <i className="feather icon-unlock auth-icon" />
            </div>
            <h3 className="mb-4">Login</h3>
            <div className="input-group mb-3">
              <input
                type="email"
                className="form-control"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="input-group mb-4">
              <input
                type="password"
                className="form-control"
                placeholder="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>

            <button
              className="btn btn-primary shadow-2 mb-4"
              onClick={loginHandler}
            >
              Login
            </button>
            <p className="mb-2 text-muted">
              Forgot password? <Link to="/reset">Reset</Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
})

export default Login
