import * as actionTypes from './actions'
import config from 'src/config'

const initialState = {
  isOpen: [], //for active default menu
  isTrigger: [], //for active default menu, set blank for horizontal
  ...config,
  isFullScreen: false, // static can't change X    .,Z
  isCounter: false,
  user: null,
  viewStyle: 'card',
  chats: [],
  notifications: [],
}
const addNotification = (ar, orig) => {
  ar.map((elem) => {
    const index = orig.findIndex((a) => a.report_id === elem.report_id)

    // index !== -1 && (orig[index].count += elem.count) : orig.push(elem)
    if (index !== -1) {
      orig[index].count = 1
      orig[index].count += elem.count
    } else {
      // elem.count = 1
      orig.push(elem)
    }
  })

  return orig
}

const clearNotification = (report_id, orig) => {
  return orig.filter((notification) => notification.id !== report_id)
}

const reducer = (state = initialState, action) => {
  let trigger = []
  let open = []

  switch (action.type) {
    case 'increment_counter':
      return {
        ...state,
        isCounter: action.payload,
      }
    case 'store_user':
      return {
        ...state,
        user: action.payload,
      }
    case 'set_view_style':
      return {
        ...state,
        viewStyle: action.payload,
      }
    case 'seed_initial_chats':
      const overallChats = [...state.chats, ...action.payload]
      return {
        ...state,
        chats: overallChats,
      }
    case 'addChat':
      //add array to chats
      const newChats = [...state.chats, action.payload]
      return {
        ...state,
        chats: newChats,
      }
    case 'add_notification':
      //add array to chats
      const orig = [...state.notifications]
      const newArr = addNotification(action.payload, orig)
      return {
        ...state,
        notifications: newArr,
      }

    case 'clear_notification':
      //add array to chats
      const cleared = clearNotification(action.payload, state.notifications)
      return {
        ...state,
        notifications: cleared,
      }
    case 'setdefaultpath':
      return {
        ...state,
        defaultPath: action.payload,
      }
    case actionTypes.COLLAPSE_MENU:
      return {
        ...state,
        collapseMenu: !state.collapseMenu,
      }
    case actionTypes.COLLAPSE_TOGGLE:
      if (action.menu.type === 'sub') {
        open = state.isOpen
        trigger = state.isTrigger

        const triggerIndex = trigger.indexOf(action.menu.id)
        if (triggerIndex > -1) {
          open = open.filter((item) => item !== action.menu.id)
          trigger = trigger.filter((item) => item !== action.menu.id)
        }

        if (triggerIndex === -1) {
          open = [...open, action.menu.id]
          trigger = [...trigger, action.menu.id]
        }
      } else {
        open = state.isOpen
        const triggerIndex = state.isTrigger.indexOf(action.menu.id)
        trigger = triggerIndex === -1 ? [action.menu.id] : []
        open = triggerIndex === -1 ? [action.menu.id] : []
      }

      return {
        ...state,
        isOpen: open,
        isTrigger: trigger,
      }
    case actionTypes.NAV_CONTENT_LEAVE:
      return {
        ...state,
        isOpen: open,
        isTrigger: trigger,
      }
    case actionTypes.NAV_COLLAPSE_LEAVE:
      if (action.menu.type === 'sub') {
        open = state.isOpen
        trigger = state.isTrigger

        const triggerIndex = trigger.indexOf(action.menu.id)
        if (triggerIndex > -1) {
          open = open.filter((item) => item !== action.menu.id)
          trigger = trigger.filter((item) => item !== action.menu.id)
        }
        return {
          ...state,
          isOpen: open,
          isTrigger: trigger,
        }
      }
      return { ...state }
    case actionTypes.FULL_SCREEN:
      return {
        ...state,
        isFullScreen: !state.isFullScreen,
      }
    case actionTypes.FULL_SCREEN_EXIT:
      return {
        ...state,
        isFullScreen: false,
      }
    case actionTypes.CHANGE_LAYOUT:
      return {
        ...state,
        layout: action.layout,
      }
    default:
      return state
  }
}

export default reducer
