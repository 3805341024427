import React, { useState } from 'react'
import { NavLink, Redirect, withRouter } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import 'src/assets/scss/style.scss'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { pathuser, validateEmail } from 'src/functions/Main'
const ResetPassword = withRouter(({ history }) => {
  toast.configure()
  const dispatch = useDispatch()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [newPasswordAgain, setNewPasswordAgain] = useState('')
  const [correctCodeEntered, setCorrectCodeEntered] = useState(false)
  const [justReceivedTheCode, setJustReceivedTheCode] = useState(false)
  const [formToShow, setFormToshow] = useState('default')
  const [code, setCode] = useState('')
  // const url = `${process.env.REACT_APP_MAIN_URL}/api/users/login`

  // const loginHandler = () => {
  //   const dataToSend = {
  //     email,
  //     password,
  //   }
  //   fetch(url, {
  //     method: 'POST',
  //     headers: new Headers({
  //       Accept: 'application/json',
  //       // Authorization: 'Bearer ' + user.token,
  //       'Content-Type': 'application/json',
  //     }),
  //     body: JSON.stringify(dataToSend),
  //   })
  //     .then((res) => res.json())
  //     .then((res) => {
  //       if (res.errorCode) {
  //         return toast.error(res.errorMessage, {
  //           position: toast.POSITION.TOP_CENTER,
  //         })
  //       }

  //       localStorage.setItem('sff_token', res.token)
  //       const path = pathuser(res.role)
  //       history.push(`/dashboard/${path}`)
  //       window.location.reload(false)
  //       // dispatch({ type: 'store_user', payload: toSend })

  //       return toast.success(res.message, {
  //         position: toast.POSITION.TOP_CENTER,
  //       })
  //     })
  //     .catch((err) => {
  //       console.log(err)
  //       return toast.warning('An error occured while reaching the server', {
  //         position: toast.POSITION.TOP_CENTER,
  //       })
  //     })
  //   // return console.log(toSend)
  // }
  const url = `${process.env.REACT_APP_MAIN_URL}/api/users/verifyOtp`

  const sendCode = (values) => {
    toast.info('We are verifying your code...', {
      position: toast.POSITION.TOP_LEFT,
      hideProgressBar: true,
    })
    const dataToSend = {
      email: email,
      otp: code,
    }
    // send request
    fetch(url, {
      method: 'POST',
      headers: new Headers({
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(dataToSend),
    })
      .then((res) => res.json())
      .then((res) => {
        // alert(JSON.stringify(res));
        if (res.data?.otp === dataToSend.otp) {
          toast.success('Proceed to reset your password', {
            position: toast.POSITION.TOP_RIGHT,
          })

          //show pane to reset password
          return setFormToshow('correctCodeEntered')
        } else {
          toast.error('You have entered the wrong code', {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
          })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const resetPassword = () => {
    if (
      newPassword === '' ||
      newPassword.length < 6 ||
      newPassword !== newPasswordAgain
    ) {
      return toast.error(
        'Please enter a password of atleast 6 characters. Ensure the passwords match too.',
        {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
        },
      )
    } else {
      toast.info('Resetting Password...', {
        position: toast.POSITION.TOP_LEFT,
      })
      const url = `${process.env.REACT_APP_MAIN_URL}/api/users/updatePassword`
      const dataToSend = {
        email: email,
        password: newPassword,
      }
      fetch(url, {
        method: 'POST',
        headers: new Headers({
          Accept: 'application/json',
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify(dataToSend),
      })
        .then((res) => res.json())
        .then((res) => {
          // console.log(res)
          if (res?.success === 1) {
            toast.success(
              'Password reset successfully. Login Using the new password.',
              {
                position: toast.POSITION.TOP_RIGHT,
              },
            )
            return history.push(`/login`)
          } else {
            toast.error('There was an error resetting the password', {
              position: toast.POSITION.TOP_CENTER,
              hideProgressBar: true,
            })
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  const getCode = () => {
    // url for otp
    const otpUrl = `${process.env.REACT_APP_MAIN_URL}/api/users/getOtp`
    if (email !== '' && validateEmail(email)) {
      toast.info('We are processing your request...', {
        position: toast.POSITION.TOP_LEFT,
        hideProgressBar: true,
      })
      const dataToSend = { email: email }
      fetch(otpUrl, {
        method: 'POST',
        headers: new Headers({
          Accept: 'application/json',
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify(dataToSend),
      })
        .then((res) => res.json())
        .then((res) => {
          // alert(JSON.stringify(res));
          if (res?.success === 1) {
            toast.success(
              'We have sent a code to your email, please enter it in the below field',
              {
                position: toast.POSITION.TOP_RIGHT,
              },
            )

            return setFormToshow('justReceivedTheCode')
          } else {
            toast.error(
              'Please try again. An error occured while sending the code.',
              {
                position: toast.POSITION.TOP_CENTER,
                hideProgressBar: true,
              },
            )
          }
        })
        .catch((err) => {
          console.log(err)
        })
    } else {
      return toast.error('Please enter a valid email address', {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
      })
    }
  }
  return (
    <div className="auth-wrapper">
      <div className="auth-content">
        <div className="auth-bg">
          <span className="r" />
          <span className="r s" />
          <span className="r s" />
          <span className="r" />
        </div>
        <div className="card">
          <div className="card-body text-center">
            <div className="mb-4">
              <i className="feather icon-unlock auth-icon" />
            </div>
            <h3 className="mb-4">Reset Password</h3>
            {formToShow === 'default' && (
              <>
                <div className="input-group mb-3">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <button
                  className="btn btn-primary shadow-2 mb-4"
                  onClick={getCode}
                >
                  Proceed
                </button>
              </>
            )}
            {formToShow === 'justReceivedTheCode' && (
              <>
                <p>Enter the code you just received</p>
                <div className="input-group mb-3">
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Code"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                  />
                </div>
                <button
                  className="btn btn-primary shadow-2 mb-4"
                  onClick={sendCode}
                >
                  Proceed
                </button>
              </>
            )}
            {formToShow === 'correctCodeEntered' && (
              <>
                <p>Now reset your password</p>
                <div className="input-group mb-3">
                  <input
                    type="password"
                    className="form-control"
                    placeholder="new Password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                </div>
                <div className="input-group mb-3">
                  <input
                    type="password"
                    className="form-control"
                    placeholder="confirm Password"
                    value={newPasswordAgain}
                    onChange={(e) => setNewPasswordAgain(e.target.value)}
                  />
                </div>
                <button
                  className="btn btn-primary shadow-2 mb-4"
                  onClick={resetPassword}
                >
                  Complete
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
})

export default ResetPassword
