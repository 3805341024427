//time conversion:
export const tConv24 = (time24) => {
  var ts = time24
  var H = +ts.substr(0, 2)
  var h = H % 12 || 12
  //   h = h < 10 ? '0' + h : h // leading 0 at the left for 1 digit hours
  var ampm = H < 12 ? ' AM' : ' PM'
  let tym = ts.substr(3, 3)
  tym = parseInt(tym) < 10 ? ':0' + parseInt(tym) : ':' + tym
  ts = h + tym + ampm
  return ts
}

export const pathuser = (role) => {
  let path = ''
  if (role === 'Sales Rep') {
    path = 'salesrep'
  }
  if (role === 'Instructor') {
    path = 'instructor'
  }
  if (role === 'Client') {
    path = 'users'
  }
  if (role === 'Admin') {
    path = 'admin'
  }
  return path
}

export const trimDate = (date, format = null) => {
  if (
    date === '0000-00-00' ||
    date === undefined ||
    date === null ||
    date === ''
  ) {
    return ''
  }
  const myNewDate = new Date(date)
  if (format === 'date_only') {
    const finalDate = myNewDate.toISOString().slice(0, 10)
    return finalDate
  } else if (format === 'date_time') {
    const finalDate =
      myNewDate.toISOString().slice(0, 10) +
      ' ' +
      myNewDate.getHours() +
      ':' +
      myNewDate.getMinutes() +
      ':' +
      myNewDate.getSeconds()
    return finalDate
  } else if (format === 'time_only') {
    const finalDate =
      myNewDate.getHours() +
      ':' +
      myNewDate.getMinutes() +
      ':' +
      myNewDate.getSeconds()
    return finalDate
  } else {
    return String(myNewDate)
  }
}

export const setGlobals = (val, action, dispatch) => {
  console.log(action, val)
  if (action === 'set_view_style') {
    dispatch({ type: 'set_view_style', payload: val })
  }
}

export function searchForLocation(data) {
  // return console.log(data)
  let toReturn = ''
  data.map((item) => {
    if (item.short_name.includes('County')) {
      toReturn = item.short_name
    } else if (item.short_name.includes('District')) {
      toReturn = item.short_name
    } else {
      toReturn = -1
    }
  })
  if (toReturn === -1) {
    if (String(data[data.length - 2].short_name).length <= 2) {
      //I don't want country u moron
      return data[data.length - 3].short_name
    } else {
      return data[data.length - 2].short_name
    }
  } else {
    return toReturn
  }
}

const predifined_list = ['school', 'church', 'establishment']
export function refineType(type) {
  let toReturn = ''
  predifined_list.map((item) => {
    if (type.includes(item)) {
      console.log(item)
      toReturn = item
    } else {
      toReturn = -1
    }
  })
  if (toReturn === -1) {
    //back to sender
    return type
  } else {
    return toReturn
  }
}

export const checkForNotification = (reportId, arr) => {
  const filtered = arr.filter((e) => e.report_id === reportId)
  if (filtered.length > 0) {
    return filtered[0].count
  } else {
    return false
  }
}

export const trimString = (str, length) => {
  if (!str) {
    return ''
  }
  if (str.length > 13) {
    return str.substring(0, length - 3) + ' ...'
  } else {
    return str.substring(0, length)
  }
}

export const validateEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email)
}
